<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-card header="Confirm" class="mt-4">
          <page-snippet name="release-create-outtro" class="" />

          <page-snippet name="priority-release-submit" v-if="getAttribute(release, 'ReleasePriority') == 'High'" />

          <field
            v-if="getAttribute(release, 'ReleasePriority') != 'High'"
            name="DeliveryStatus"
            :defs="fieldDefs"
            :model="release"
             />

          <template v-if="$root.$data.me.IsSuperUser">
            <!--
            <strong>Kies type</strong>
            <b-select
              :options="testOptions"
              v-model="testOption"
            /> -->

            <div class="mt-5" v-if="createdRelease">
              <p>{{ createdRelease.Status }}</p>
              <p>{{ createdRelease.ApiError }}</p>
            </div>
          </template>

          <b-button class="mt-4" @click="$emit('create', testOption)">Create release</b-button>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import ReleaseConceptHelper from '@/mixins/ReleaseConceptHelper'

  export default {
    name: 'Confirm',
    components: {
      'page-snippet': () => import('@/components/PageSnippet'),
      'field': () => import('@/components/ReleaseConceptField'),
    },
    mixins: [
      ReleaseConceptHelper
    ],
    data () {
      return {
        fieldDefs: this.$root.$data.fieldDefs.Album,
        testOption: 'commit',
        testOptions: [
          { text: 'Test API call, niet echt aanmaken.', value: 'nocommit' },
          { text: 'Echte API call, release aanmaken.', value: 'commit' },
        ]
      }
    },
    props: {
      release: Object,
      createdRelease: Object,
    },
    watch: {
    },
    methods: {

    },
    computed: {
      readyToProceed () {
        return false
      }
    },
    mounted () {

    }
  }
</script>

<style lang="scss" scoped>

</style>